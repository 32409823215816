import { Text } from '@medsimples/design-system/src/chakra';
import { useDesignTokens } from '../../app/providers/design_tokens_provider';

export default function MedSimples() {
  const tokens = useDesignTokens();
  return (
    <>
      <Text as='span' fontWeight='700' color={tokens.text.primary.color}>
        Med
      </Text>
      <Text as='span' fontWeight='700' color={tokens.text.highlight.color}>
        Simples
      </Text>
    </>
  );
}
