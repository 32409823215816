'use client';

import { type ReactNode, createContext, useContext } from 'react';
import type { TokensType } from '../../styles/tokens';

const DesignTokensContext = createContext<TokensType>({} as TokensType);

const DesignTokensProvider = ({
  tokens,
  children,
}: { tokens: TokensType; children: ReactNode }) => {
  return (
    <DesignTokensContext.Provider value={tokens}>
      {children}
    </DesignTokensContext.Provider>
  );
};

export default DesignTokensProvider;

export const useDesignTokens = () => {
  return useContext(DesignTokensContext);
};
