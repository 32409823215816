import styled from 'styled-components';
import { baseStyles } from '../../styles';

export const StyledStepper = styled.div`
    ${baseStyles};
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 2rem auto;
`;

export const StyledStepperIndicator = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    list-style: none;
    background-color: transparent;
`;

export const StyledStepperIndicatorItem = styled.li<{
  color: string;
  isFilled?: boolean;
  isDisabled?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    background-color: #ffffff;
    color: ${(props) => props.color};
    border: 0.125rem solid ${(props) => props.color};
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    z-index: 1;
    transition: background-color 0.5s ease-in;
    -webkit-transition: background-color 0.5s ease-in;
    -moz-transition: background-color 0.5s ease-in;
    -ms-transition: background-color 0.5s ease-in;
    -o-transition: background-color 0.5s ease-in;

    ${({ isFilled, color }) =>
      isFilled &&
      ` background-color: ${color};  transition: all 0.5s ease-in;
    color: #FFFFFF;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: background-color 0.5s ease-in;
  -o-transition: background-color 0.5s ease-in;
}`}

    ${({ isDisabled }) =>
      isDisabled &&
      ` border: 0.125rem solid #999999;   
    color: #999999; 
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -ms-transition: background-color 0.5s ease-in;
  -o-transition: background-color 0.5s ease-in;
}`}
`;

export const StyledStepperProgressBar = styled.progress<{
  color: string;
}>`
    position: absolute;
    left: 0;
    height: 0.125rem;
    width: 100%;
    background-color: #999999;

    &::-webkit-progress-bar {
        background-color: #999999;
    }

    &::-moz-progress-bar {
        background-color: ${(props) => props.color};
    }

    &::-webkit-progress-value {
        background-color: ${(props) => props.color};
        transition: width 0.3s ease-in, height 0.3s ease-in;
        -webkit-transition: width 0.3s ease-in, height 0.3s ease-in;
        -moz-transition: width 0.3s ease-in, height 0.3s ease-in;
        -ms-transition: width 0.3s ease-in, height 0.3s ease-in;
        -o-transition: width 0.3s ease-in, height 0.3s ease-in;
    }

    &[value] {
        appearance: none;
        border: 0;
        color: ${(props) => props.color};
        transition: width 0.3s ease-in, height 0.3s ease-in;
        -webkit-transition: width 0.3s ease-in, height 0.3s ease-in;
        -moz-transition: width 0.3s ease-in, height 0.3s ease-in;
        -ms-transition: width 0.3s ease-in, height 0.3s ease-in;
        -o-transition: width 0.3s ease-in, height 0.3s ease-in;
    }
`;
