import { css } from 'styled-components';

export const colors = {
  transparent: 'Transparent',
  info: '#3a8ccf',
  white: '#ffffff',
  gray: '#999999',
  black: '#000000',
  danger: '#b33030',
  success: '#008000',
  warning: '#f5d252',
  shadow: '#000000',
  highlight: '#212529',
  primary: '#3257A8',
  secondary: '#000842',
  font: '#000000',
  disabled: '#aaaaaa',
  icon: css`
    invert(12%) sepia(16%) saturate(421%) hue-rotate(169deg)
      brightness(92%) contrast(93%);
  `,
  iconHighlight: css`
    invert(100%) sepia(28%) saturate(1271%) hue-rotate(179deg)
      brightness(103%) contrast(96%);
  `,
};
