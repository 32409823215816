import type React from 'react';
import type { CSSProperties, ReactNode } from 'react';
import {
  StyledStepper,
  StyledStepperIndicator,
  StyledStepperIndicatorItem,
  StyledStepperProgressBar,
} from './Stepper.style';

interface StepperProps {
  /**
   * Color of active steps
   */
  color: string;
  /**
   * additional style
   */
  style?: CSSProperties;
  /**
   * Current step active
   */
  activeStepIndex: number;
  /**
   * Array of objects defining the indicator for each stepper's steps
   */
  stepsMetadata: Array<{
    indicator?: ReactNode;
  }>;
}

export const Stepper: React.FC<StepperProps> = ({
  activeStepIndex,
  stepsMetadata,
  color,
  ...props
}) => {
  const progressPerStep = 100 / (stepsMetadata.length + 1);

  const getProgressBarPercentage = (): number =>
    (activeStepIndex + 1) * progressPerStep;

  return (
    <StyledStepper data-steps={stepsMetadata.length} {...props}>
      <StyledStepperIndicator role='list' aria-label='progress'>
        <StyledStepperProgressBar
          value={getProgressBarPercentage()}
          color={color}
          max='100'
        />

        {stepsMetadata.map((step, index: number) => (
          <StyledStepperIndicatorItem
            key={`step-indicator-${index + Math.random()}`}
            aria-current={'step'}
            color={color}
            isFilled={index === activeStepIndex}
            isDisabled={index > activeStepIndex}
          >
            {step.indicator || index + 1}
          </StyledStepperIndicatorItem>
        ))}
      </StyledStepperIndicator>
    </StyledStepper>
  );
};
