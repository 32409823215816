import styled from 'styled-components';
import { baseStyles } from '../../styles';

export const ContainerStyled = styled.div<{ color: string }>`
    ${baseStyles};
    position: relative;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.color};
    border-radius: 15px;
    padding: 1.25rem;
`;

export const ContainerLabelStyled = styled.div<{
  color: string;
}>`
    ${baseStyles}
    position: absolute;
    top: -0.65rem;
    left: 0.75rem;
    background-color: #ffffff;
    color: ${(props) => props.color};
    font-size: 14px;
    font-weight: 500;
    padding: 0 0.25rem;
`;
