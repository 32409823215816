import { ChakraProvider } from '@chakra-ui/react';
import { type ReactNode, forwardRef } from 'react';

interface ProviderProps {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  theme?: Record<string, any>;
  children: ReactNode;
}

export const Provider = forwardRef<typeof ChakraProvider, ProviderProps>(
  ({ children, theme, ...props }, _ref) => {
    return (
      <ChakraProvider {...props} theme={theme}>
        {children}
      </ChakraProvider>
    );
  },
);
Provider.displayName = 'Provider';
