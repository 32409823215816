import { Button as ButtonChakra, type ButtonProps } from '@chakra-ui/react';
import type React from 'react';
import { forwardRef } from 'react';

export const Button: React.FC<ButtonProps & { href?: string }> = forwardRef<
  typeof ButtonChakra,
  ButtonProps
>(({ children, colorScheme, size, w, ...props }, ref) => {
  return (
    <ButtonChakra
      ref={ref}
      {...props}
      borderRadius='32px'
      fontWeight={500}
      colorScheme={colorScheme || 'blue'}
      fontSize='md'
      size={size || 'lg'}
      w={w || '100%'}
      boxShadow={'1px 3px 5px rgba(0, 0, 0, 0.2);'}
    >
      {children}
    </ButtonChakra>
  );
});
Button.displayName = 'Button';
